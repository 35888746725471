import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

const mfeConfigs: MFEConfig[] = __MFEConfigs__;
const devtoolsOverrides: Record<string, string> = __DEVTOOLS_OVERRIDES__;


if (Object.keys(devtoolsOverrides).length) {
  if (localStorage.getItem("devtools") === null) {
    localStorage.setItem('devtools', 'true');
    
    Object.entries(devtoolsOverrides).forEach(([key, value]) => {
      localStorage.setItem(`import-map-override:${key}`, value);
    });

    localStorage.setItem('import-map-overrides-disabled', JSON.stringify(Object.keys(devtoolsOverrides)));
  }
}

const navRoute = {type: "nav", routes: [{type: "application", name: "@par/support-portal-auth", props: { 
  config: { navLinks: mfeConfigs.reduce((acc, curr) => curr.navLinks?.length ? [...acc, ...curr.navLinks] : acc, [])}}
}]};

const mfeRoutes = mfeConfigs.map(mfe => ({ type: "route",
    routes: [{ type: "application", name: mfe.name, props: { ...(mfe.props || {}) } }],
    ...(mfe.path ? { path: mfe.path } : { default: true })}));

const routes = constructRoutes({ routes: [navRoute, ...mfeRoutes] });

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
